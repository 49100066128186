import React, { useState } from 'react';
import AdventCalendarModel from 'models/AdventCalendarModel';
import ModelImage from 'components/ModelImage';
import RouteUtils from 'utils/RouteUtils';
import GenericUtils from 'utils/GenericUtils';

type Props = {
  model: AdventCalendarModel;
  open: boolean;
  available: boolean;
  onClick?(m: AdventCalendarModel): void;
};

export default function AdventCalendarCard({ model, open, available, onClick }: Props) {

  const [imageId] = useState(Math.floor(Math.random() * 5) + 3);
  const [imageClassname] = useState(Math.random() < 0.5 ? 'advent-calendar-card__icon--left' : 'advent-calendar-card__icon--right');

  const handleOnClick = () => {
    if (onClick) {
      onClick(model);
    }
  };

  let view;
  if (open) {
    if (available) {

      view = (
        <a href={RouteUtils.generatePathToModelRoom({ username: model.username })}>
          <ModelImage
            model={{
              username: model.username,
            }}
            className="advent-calendar-card__content"
          />

          <div className="advent-calendar-card__model-info__day">
            <span>
              {model.day}
            </span>
          </div>

          <div className="advent-calendar-card__model-info__username">
            <span>
              {model.username}
            </span>
          </div>

          <img
            src={`/img/advent-calendar/christmas-decoration-${imageId}.png`}
            className={GenericUtils.classNames('advent-calendar-card__icon', imageClassname)}
          />
        </a>
      );
    } else {
      view = (
        <img
          src="/img/advent-calendar/not-available.gif"
          className="advent-calendar-card__content"
        />
      );
    }
  } else {
    view = (
      <div className="advent-calendar-card__content advent-calendar-card__closed__background">
        <span className="advent-calendar-card__day">{model.day}</span>

        <img
          src="/img/advent-calendar/christmas-decoration-2.png"
          style={{
            animationDelay: `${Math.random()}s`,
          }}
          className="advent-calendar-card__closed__decoration--top-left"
        />

        <img
          src="/img/advent-calendar/christmas-decoration-1.png"
          className="advent-calendar-card__closed__decoration--bottom-right"
        />
      </div>
    );
  }

  return (
    <div
      onClick={handleOnClick}
      className={GenericUtils.classNames('advent-calendar-card', !open ? 'advent-calendar-card--closed' : undefined, available ? 'advent-calendar-card--available' : undefined)}
    >
      <div className="advent-calendar-card__container">
        {view}
      </div>
    </div>
  );
}
