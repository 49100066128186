import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { cloneDeep } from 'lodash';
import Master from 'layouts/Master';
import AdventCalendarCard from 'components/AdventCalendarCard';
import Model from 'models/Model';
import Tag from 'models/Tag';
import AdventCalendarModel from 'models/AdventCalendarModel';
import Config from 'config/Config';
import GenericUtils from 'utils/GenericUtils';

type Data = {
  pageData: {
    models: Model[];
    tags: Tag[];
  };
};

type Props = {
  data: Data;
};

const AdventCalendarModels: AdventCalendarModel[] = [
  {
    username: 'sabinadulce',
    day: 1,
  },
  {
    username: 'asian_delight_',
    day: 2,
  },
  {
    username: 'efetishism',
    day: 3,
  },
  {
    username: 'heyhorny_cb',
    day: 4,
  },
  {
    username: 'wildtequilla',
    day: 5,
  },
  {
    username: 'little_flower_',
    day: 6,
  },
  {
    username: 'haileygrx',
    day: 7,
  },
  {
    username: 'hotfallingdevil',
    day: 8,
  },
  {
    username: 'hollings',
    day: 9,
  },
  {
    username: 'xfunfiestax',
    day: 10,
  },
  {
    username: 'mystique_web',
    day: 11,
  },
  {
    username: 'raquelle_star',
    day: 12,
  },
  {
    username: 'jenny_wet_',
    day: 13,
  },
  {
    username: 'crazy_korean',
    day: 14,
  },
  {
    username: 'lianafox',
    day: 15,
  },
  {
    username: 'belka22',
    day: 16,
  },
  {
    username: 'alicee_diaz',
    day: 17,
  },
  {
    username: '_little__kittyy',
    day: 18,
  },
  {
    username: 'looouise',
    day: 19,
  },
  {
    username: '_lorene_',
    day: 20,
  },
  {
    username: 'candyxtreo',
    day: 21,
  },
  {
    username: 'abogailmaartin',
    day: 22,
  },
  {
    username: 'classy_fuck',
    day: 23,
  },
  {
    username: 'realtoxxxmaria',
    day: 24,
  },
  {
    username: 'caylin',
    day: 25,
  }
];

const LOCAL_STORAGE_KEY = 'advent_calendar_2020';

export default function AdventCalendar2020Page({ data }: Props) {
  const now = new Date();

  const getOpened = (): number[] => {
    let opened: number[] = [];

    // No SSR
    if (typeof localStorage !== 'undefined') {
      const strValue = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (strValue !== null) {
        opened = strValue.split(';')
          .filter(v => v.trim().length > 0)
          .map(v => parseInt(v, 10));
      }
    }

    return opened;
  };

  const onCardClick = (m: AdventCalendarModel) => {
    const event = {
      'event': 'advent-calendar-card-clicked',
      'day': m.day,
      'modelUsername': m.username,
      'isAvailable': false,
    };

    if (openedDays.indexOf(m.day) !== -1) {
      return;
    }

    const nextOpenedDays = cloneDeep(openedDays);
    nextOpenedDays.push(m.day);
    setOpenedDays(nextOpenedDays);

    const isAvailable = new Date(`2020-12-${m.day}`).valueOf() <= now.valueOf();

    event.isAvailable = isAvailable;

    if (isAvailable) {

      if (typeof localStorage !== 'undefined') {
        let strOpenedDays = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!strOpenedDays) {
          strOpenedDays = '';
        }
        localStorage.setItem(LOCAL_STORAGE_KEY, strOpenedDays + `${m.day};`);
      }
    }

    GenericUtils.sendGTMEvent(event);
  };

  const renderCard = (m: AdventCalendarModel) => {
    const isOpen = openedDays.indexOf(m.day) !== -1;
    const isAvailable = new Date(`2020-12-${m.day}`).valueOf() <= now.valueOf();

    return (
      <AdventCalendarCard
        key={m.username}
        model={m}
        open={isOpen}
        available={isAvailable}
        onClick={onCardClick}
      />
    );
  };

  const [openedDays, setOpenedDays] = useState(getOpened());

  return (
    <Master title={`${Config.SiteTitle} | ${Config.SiteDescription}`} mainClassName="advent-calendar__main">
      <div className="container">
        <div className="advent-calendar__header">
          <h1 className="advent-calendar__title">
            Tits Advent Calendar 2020
          </h1>
        </div>

        <div className="advent-calendar">
          {AdventCalendarModels.map(renderCard)}
        </div>
      </div>
    </Master>
  );
}

export const pageQuery = graphql`
  {
    pageData: allCamGirl {
      models: nodes {
        username
        id
        followersCount
        tags
      },
    }
  }
`;
